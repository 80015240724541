import { useI18n } from 'vue-i18n';
import type { NestedDictionary } from '~/types/composables/useStaticPage';

function getNestedValue(dictionary: NestedDictionary, dictionaryKey: string) {
  const keys = dictionaryKey.split('.');
  let value: string | NestedDictionary | undefined = dictionary;

  for (const key of keys) {
    if (value && typeof value === 'object' && key in value) {
      value = value[key];
    } else {
      return undefined;
    }
  }

  return value;
}

export function addIndexForLocalization(dictionaryKey: string) {
  const {
    messages,
    locale,
  } = useI18n();
  const array: { dictionaryKey: string }[] = [];
  const nestedValue = getNestedValue(
    messages.value[locale.value],
    dictionaryKey,
  );

  if (typeof nestedValue === 'undefined' || !Array.isArray(nestedValue)) {
    return array;
  }

  for (let i = 0; i < nestedValue.length; i++) {
    array.push({ dictionaryKey: `${dictionaryKey}.${i}` });
  }

  return array;
}
